//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Menu, MenuItem, MenuItemGroup, Submenu } from "element-ui";
import { ctrlBase } from "@/controller";
export default {
    components: {
        [Menu.name]: Menu,
        [Submenu.name]: Submenu,
        [MenuItem.name]: MenuItem,
        [MenuItemGroup.name]: MenuItemGroup,
    },
    data() {
        return {
            active: "/order/index",
            list: [
                { name: "我的訂單", link: "/order/index" },
                { name: "我的課程", link: "/course/index" },
                { name: "個人資訊", link: "/user/profile" },
                { name: "折扣券", link: "/user/coupon" },
                // { name: "收貨地址", link: "/user/address" },
            ],
        };
    },
    created() {},
    computed: {},
    watch: {
        "$route.path": {
            handler(newName, oldName) {
                this.active = newName;
            },
            immediate: true,
        },
    },
    methods: {
        menuItemClick(name) {
            ctrlBase.gaSentEvent("會員主頁", `查看${name}`);
        },
    },
};
